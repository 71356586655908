<template>
  <div class="main">
    <div class="condition-filtrate-box">
      <div class="left-item">
        <el-input
          class="select-width id-code-width"
          size="medium"
          v-model="queryForm.name"
          placeholder="角色名称" />
      </div>

      <div class="right-item">
        <el-button type="primary" size="small" @click="queryUserInfo">查询</el-button>
        <el-button type="primary" size="small" @click="addUserInfo" v-btn-permission="'008006006001'">新增</el-button>
      </div>
    </div>

    <el-table height="100%" :data="tableData" row-key="id" default-expand-all border>
      <el-table-column label="操作" width="180" align="center">
        <template slot-scope="{ row }">
          <el-link type="primary" @click="editTableFun(row)" v-btn-permission="'008006006002'">编辑</el-link>
          <el-link type="danger" @click="deleteTableFun(row)" v-btn-permission="'008006006003'">删除</el-link>
          <el-link type="primary" @click="qxTableFun(row)" v-btn-permission="'008006006004'">权限</el-link>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="角色名称"></el-table-column>
      <el-table-column prop="remark" label="描述" />
      <el-table-column prop="id" label="状态">
        <template slot-scope="{ row }">
          <el-tag size="small" v-if="row.status == 1">启用</el-tag>
          <el-tag size="small" type="danger" v-else-if="row.status == 0">停用</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="updTime"  label="最后更新时间" width="200" />
    </el-table>

    <div class="content-page">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageObj.pageNumber"
        :page-size="pageObj.pageSize"
        :total="pageObj.total"
        :page-sizes="[10, 20, 30, 50, 100]"
        layout="total, sizes, prev, pager, next, jumper"
      ></el-pagination>
    </div>

    <el-dialog title="角色信息维护" :visible.sync="dialogFormVisible" :close-on-click-modal="false" >
      <el-form :model="form" ref="addForm">
        <el-form-item label="角色名称:" label-width="120px" prop="name" :rules="{ required: true, message: '不能为空', trigger: 'blur'}">
          <el-input v-model="form.name" placeholder="请输入角色名称">
          </el-input>
        </el-form-item>
        <el-form-item label="角色编码:" label-width="120px" prop="code" :rules="{ required: true, message: '不能为空', trigger: 'blur'}">
          <el-input v-model="form.code" >
            <template slot="prepend">HOTEL_</template>
          </el-input>
        </el-form-item>
        <el-form-item label="排序号:" label-width="120px" prop="seq" :rules="{ required: true, message: '不能为空', trigger: 'blur'}">
          <el-input v-model="form.seq" type="number"></el-input>
        </el-form-item>
        <el-form-item label="角色描述:" label-width="120px" prop="remark" :rules="{ required: true, message: '不能为空', trigger: 'blur'}">
          <el-input type="textarea" v-model="form.remark" :rows="5" placeholder="请输入内容" maxlength="100"  show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="状态:" label-width="120px" prop="status" :rules="{ required: true, message: '不能为空', trigger: 'blur'}">
          <el-radio-group v-model="form.status">
            <el-radio :label="1">启用</el-radio>
            <el-radio :label="0">停用</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogSubmit('addForm')">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="权限管理" :visible.sync="dialogFormVisible3" :close-on-click-modal="false" >
      <div>
        <p style="margin-left: 150px;font-size: 18px;">角色名称： {{roleName}}</p>
        <el-tree
          :data="qxData"
          node-key="resId"
          :check-strictly="checkStrictly"
          :default-expanded-keys="defaultExpandedKeys"
          ref="tree"
          @check-change="handleCheckChange"
          show-checkbox
          :props="defaultProps">
        </el-tree>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible3 = false">取 消</el-button>
        <el-button type="primary" @click="dialogSubmit3()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import TableModel from '../../components/TableModel';
import { roleList,roleSave,roleUpdate,roleResUpdate,getRoleRes,delRole  } from '../../api/SystemManagement/index.js';
import { mapState } from 'vuex';

const formObj = {//表单
        name:"",
        code:"",
        seq:1,
        remark:"",
        status: 1,
      };

export default {
  name: "RoleManage",
  components: { TableModel },
  data() {
    return {
      queryForm: {
        name:''
      },
      defaultExpandedKeys: [],
      tableData: [], //表单数据
      pageObj:{
        pageNumber: 1,
        pageSize: 20,
        total: 0,
      },
      dialogFormVisible:false,
      dialogFormVisibleType:'add',
      form:formObj,

      dialogFormVisible3:false,
      qxTableObj:{},
      qxData: [],
      roleName:'',
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      checkStrictly:false,//强关联

    }
  },

  computed: {
    ...mapState({
      isolationList: state => state.user.isolationList,
    }),
  },

  created() {
    this.queryUserInfo();
  },

  methods: {
    timeFormatter(row, column) {
      if(!row.lastModifiedDate) return '';
      let {year:y,monthValue:m,dayOfMonth:d,hour:hh,minute:mm,second:ss} = row.lastModifiedDate;
      let val = `${y}-${m}-${d} ${hh>9?hh:'0'+hh}:${mm>9?mm:'0'+mm}:${ss>9?ss:'0'+ss}`
      return val
    },
    changeFun(e){
      console.log(e);
    },
    getUserInfo(){
      let params = Object.assign({},this.queryForm,this.pageObj);
      roleList({params}).then((res)=>{
        let { code, data, msg } = res.data;
        if(code == 200){
          this.tableData = data.list;
          this.pageObj.total = Number(data.totalRow);
        }
      })
    },
    //查询
    queryUserInfo() {
      this.getUserInfo();
    },
    //新增
    addUserInfo() {
      this.dialogFormVisible = true;
      this.dialogFormVisibleType = 'add';
      this.form = formObj;
    },
    //表单新增
    dialogSubmit(formName){
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitFormFun();
        } else {
          return false;
        }
      });
    },
    submitFormFun(){
      let params = Object.assign({},this.form);

      // 在角色编码前面加上HOTEL_前缀
      params.code = 'HOTEL_' + params.code.toUpperCase();
      var API = this.dialogFormVisibleType == 'add'?roleSave:roleUpdate;
      API(params).then((res)=>{
        let { code, data, msg } = res.data;
        if(code == 200){
          this.dialogFormVisible = false;
          this.$message({
            type: 'success',
            message: '保存成功!'
          });
          this.queryUserInfo();
        }
      })
    },

    //table操作
    //修改
    editTableFun(row){
      this.dialogFormVisible = true;
      this.dialogFormVisibleType = 'set';
      this.form = Object.assign({},row);
      // 去掉HOTEL_前缀
      this.form.code = this.form.code.replace('HOTEL_','');
    },

    //删除
    deleteTableFun(row){
      this.$confirm(`确定要删除【${row.name}】角色吗？`, '操作确认', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          delRole({id:row.id}).then((res)=>{
            let { code, data, msg } = res.data;
            if(code == 200){
              this.$message({
                type: 'success',
                message: '删除成功!'
              });
              this.queryUserInfo();
            }
          })

        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
    },
    dialogSubmit2(row){
      console.log('删除');
    },
    //权限
    qxTableFun(row){
      this.dialogFormVisible3 = true;
      this.checkStrictly = true;
      let params = {roleId:row.id};
      this.qxTableObj = params;
      this.roleName = row.name;
      getRoleRes({params}).then((res)=>{
        let { code, data, msg } = res.data;
        if(code == 200){
          console.log("🚀 ~ file: RoleManage.vue ~ line 197 ~ roleList ~ data", data)
          this.qxData = data.sysRoleResQryDtos;
          this.defaultExpandedKeys = data.sysRoleResQryDtos.map(item => item.resId);
          this.$refs.tree.setCheckedKeys(data.resIds);
          this.checkStrictly = false;
        }
      })

    },
    // 根据选中状态获取选中的资源id
    getCheckedKeys(resList){
      let resIds = [];
      resList.forEach(item => {
        if (item.children && item.children.length > 0) {
          resIds = resIds.concat(this.getCheckedKeys(item.children));
        } else if (item.checked === '1') {
          resIds.push(item.resId);
        }
      })
      return resIds;
    },
    handleCheckChange(data, checked, indeterminate){
      console.log(data, checked, indeterminate);
    },
    dialogSubmit3(){
      console.log('权限保存');
      let arr = this.$refs.tree.getCheckedKeys();
      let sss = this.$refs.tree.getHalfCheckedKeys();
      let params = Object.assign({resIds:sss.concat(arr)},this.qxTableObj);
      console.log('params')
      console.log(params)
      roleResUpdate(params).then((res)=>{
        let { code, data, msg } = res.data;
        if(code == 200){
          console.log("🚀 ~ file: RoleManage.vue ~ line 339 ~ roleResUpdate ~ data", data)
          this.dialogFormVisible3 = false;
          this.$message({
                type: 'success',
                message: '保存成功!'
              });
        }
      })
    },


    //分页变化
    handleSizeChange(val) {
      this.pageObj.pageSize = val;
      this.getUserInfo();
    },
    handleCurrentChange(val) {
      this.pageObj.pageNumber = val;
      this.getUserInfo();
    },


  }
}
</script>

<style scoped lang="scss">
@import "../../static/css/condition-filtrate.scss";

.main {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .el-table {
      overflow-y: auto;
    }
  }

.content-page {
  margin-top: 10px;
  height: 40px;
  display: flex;
  justify-content: flex-end;
}

.el-link {
  margin-right: 10px;
}
</style>
